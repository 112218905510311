import React from "react"

import Layout, { zeusAltTheme } from "../../components/layout"
import styles from "./success.module.scss"

const RecruitSuccessPage = ({ location }) => {
  const params = new URLSearchParams(location.search)
  const disqualified = params.get("disqualified")
  return (
    <Layout theme={zeusAltTheme}>
      <div className="page-content-module">
        <div className="wrapper">
          <div className="columns no-vertical-gap is-centered ">
            <div className="column is-8-desktop">
              {disqualified ? (
                <h1 className="h-4 h--cms-title text-center mb-3">
                  We're sorry but you do not qualify to participate at this
                  time. Thank you for your interest.
                </h1>
              ) : (
                <h1 className="h-2 h--cms-title text-center mb-3">
                  Thank you! We'll be in touch.
                </h1>
              )}
            </div>
          </div>
          {!disqualified && (
            <div className={styles.body}>
              <div className="columns is-centered">
                <div className="column is-10-tablet is-6-desktop">
                  <div className="user-content text-center">
                    <p>
                      Thank you for your interest in shaping this new employee
                      health and wellness benefits product. Within the next
                      seven business days you will receive an email from Zeus
                      Jones letting you know if you've been selected to
                      participate. If you qualify, our team will work with you
                      to set up a one-hour Zoom interview. The interviews will
                      take place between February 1-18.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default RecruitSuccessPage
